body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #9e3737;
}
/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
#root{
  position: absolute;
  width: 40vw;
  margin: 0;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
} 
#mint{
  /* background-position: center;
  background-repeat: no-repeat;
  position: relative */
  /* background: yellow; */
}
#hero{
  background-image: url("hero.png");
  height: calc(100vw / 1.77);
  background-size: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #mint {
    padding-top: 160px;
  }
}

@media (max-width: 767px) {
  #mint {
    padding-top: 130px;
  }
}
@media (max-width: 1000px) {
  .swiper-container{
    display: none;
  }
  .split1{
    display: none;
  }
  .split2 .shape-fill {
    fill: #3f3f3f;
  }
}
.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
}
.containerInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerMint{
  justify-content: space-between;
  margin: 2%;
  display: flex;
}
.containerBtn{
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.textMint{
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 19px;
  padding: 2%;
  background-color: #200c0c;
  color: #b17575;
  border: 1px solid #8d3f3f;
  border-radius: 15px;
}
.swipper-section{
  background: #9e3737;
}
.swiper-container {
  position: relative;
  width: 100vw;
  height: 350px;
  margin: 25px;
  background-color: #9e3737;
} 
.swiper-slide{
  border: 3px solid #200c0c;
  border-radius: 40px;
}
.roadmap{
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #8d3f3f;
  z-index: 5;
}
.roadmap-content{
  margin-left: auto;
  margin-right: auto;
  width: 60vw;
}
.split1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.split1 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 65px;
}

.split1 .shape-fill {
  fill: #9e3737;
}
.split2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.split2 svg {
  z-index: -1;
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 95px;
  transform: rotateY(180deg);
  
}

.split2 .shape-fill {
  fill: #9e3737;
}

.page-section {
  padding: 6rem 0;
  position: relative;  
  z-index: -5;
}
.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}
.timeline > li:after, .timeline > li:before {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: right;
}
.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 10%;
  background-color: #e9ecef;
}

.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
  margin-top: 0;
  color: #b9b8b7;
}
.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
  text-transform: none;
    color: white;
}
.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}
.timeline .timeline-body .subheading{
  color: #e9ecef;
}
@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: left;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .big {
    left: 50%;
    width: 170px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 210px;
    height: 210px;
    margin-left: -105px;
  }
  .timeline > li .big {
    width: 850px;
    height: 510px;
    margin-left: -434px;
    margin-top: 20px;
  }
  .timeline > li .medium {
    width: 850px;
    height: 510px;
    margin-left: -434px;
    margin-top: 20px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
} 
.roadmap-update{
  text-align: center;
  background-color: #212529;
  padding-top: 25px;
}
.image1 {
  background-image: url('./assets/images/collection/1.png');
  background-size: cover;
  background-position: center;
}

.image2 {
  background-image: url('./assets/images/collection/2.png');
  background-size: cover;
  background-position: center;
}
.image3{
  background-image: url('./assets/images/collection/3.png');
  background-size: cover;
  background-position: center;
}
.image4{
  background-image: url('./assets/images/collection/4.png');
  background-size: cover;
  background-position: center;
}
.image5{
  background-image: url('./assets/images/collection/5.png');
  background-size: cover;
  background-position: center;
}
.image6{
  background-image: url('./assets/images/collection/6.png');
  background-size: cover;
  background-position: center;
}
.image7{
  background-image: url('./assets/images/collection/7.png');
  background-size: cover;
  background-position: center;
}
.image8{
  background-image: url('./assets/images/collection/8.png');
  background-size: cover;
  background-position: center;
}
.image9{
  background-image: url('./assets/images/collection/9.png');
  background-size: cover;
  background-position: center;
}
.image10{
  background-image: url('./assets/images/collection/10.png');
  background-size: cover;
  background-position: center;
}
.image11{
  background-image: url('./assets/images/collection/11.png');
  background-size: cover;
  background-position: center;
}
#roadmap{
  display: none;
}